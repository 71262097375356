import useTranslationCustom from "../assets/image/community-section-1/useTranslationCustom";

function useRouter() {
  const { t } = useTranslationCustom({
    prefix: "",
    nameSpace: "global_navigation",
  });

  return [
    {
      label: t("eqbr.index"),
      sub: [
        {
          label: t("eqbr.introduction"),
          link: "/eqbr/introduction",
        },
        {
          label: t("eqbr.community"),
          link: "/eqbr/community",
        },
        {
          label: t("eqbr.brand"),
          link: "/eqbr/brand",
        },
      ],
    },
    {
      label: t("tech_and_service.index"),
      sub: [
        {
          label: t("tech_and_service.tech"),
          link: "/tech-service/tech",
        },
        {
          label: t("tech_and_service.service"),
          link: "/tech-service/service",
        },
      ],
    },
    {
      label: t("newsroom.index"),
      sub: [
        {
          label: t("newsroom.news"),
          link: "/newsroom",
        },
      ],
    },
    {
      label: t("employment.index"),
      sub: [
        {
          label: t("employment.team_culture"),
          link: "/employment/team-culture",
        },
        {
          label: t("employment.employment_recruit"),
          link: "/employment/recruit",
        },
      ],
    },
  ];
}

export default useRouter;
