import React, {useMemo} from 'react';
import YouTube from "react-youtube";
import { useResponsive } from 'view/styles/Responsive';

const youtube_width_small = 370;
const youtube_height_small = 206;
const youtube_width_medium = 792;
const youtube_height_medium = 429;
const youtube_width_extra_large = 1200;
const youtube_height_extra_large = 675;

function YouTubeComponent(props) {
    const {isTablet, isDesktop} = useResponsive();
    const youtubeWidth = useMemo(()=>{
        let width= youtube_width_small;
        if(isTablet){
            width= youtube_width_medium;
        }
        if(isDesktop){
            width= youtube_width_extra_large;
        }
        return width;
    },[isTablet, isDesktop])
    const youtubeHeight = useMemo(()=>{
        let height= youtube_height_small;
        if(isTablet){
            height= youtube_height_medium;
        }
        if(isDesktop){
            height= youtube_height_extra_large;
        }
        return height;
    },[isTablet, isDesktop])
    const youtubeOpts = useMemo(() => {
        return {
            width: youtubeWidth,
            height: youtubeHeight,
        }
    }, [youtubeWidth, youtubeHeight])
    return <YouTube opts={youtubeOpts}
        {...props}
    />
}

export default YouTubeComponent;
