import React from "react";
import EmploymentRecruitMain from "../components/employmentRecruit/EmploymentRecruitMain";
import PageHeader from "../molcules/PageHeader";
import "./EmploymentRecruit.scss";

function EmploymentRecruit(props) {
  return (
    <div id={'employment-recruit'}>
      <PageHeader prefix={"recruit"} />
      <EmploymentRecruitMain />
    </div>
  );
}

export default EmploymentRecruit;
