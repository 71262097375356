import _ from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import Globe from 'react-globe.gl'
import { countries } from "../assets/countries";

const N = 20;
const ARC_COLOR = "rgba(106, 156, 229, 0.6)"
const POINT_COLOR = "rgba(0, 255, 255, 0.4)"
const POLYGON_COLOR = "rgba(86, 136, 209, 0.9)"

export default function GlobeCompenent({width=580, height=580}) {

    const points = useMemo(() => countries.features.reduce((res, item) => {
        return [...res, ...item.geometry.coordinates.reduce((res2, coord) => {
            return [...res2, ...coord]
        }, [])].filter(res3 => _.isNumber(res3[0]) && _.isNumber(res3[1]))
    }, []), [])

    const randomNumberArray = useMemo(() => {
        const temp = [];
        while (temp.length < N) {
            const temp2 = [];
            temp2.push(parseInt(Math.random() * points.length))
            temp2.push(parseInt(Math.random() * points.length))
            temp.push(temp2)
        }
        return temp
    }, [points])

    const arcsData = useMemo(() => {
        return randomNumberArray.map(arc => ({
            startLat: points[arc[0]][1],
            startLng: points[arc[0]][0],
            endLat: points[arc[1]][1],
            endLng: points[arc[1]][0],
            color: ARC_COLOR 
        }))
    }, [points, randomNumberArray])

    const ringsData = useMemo(() => {
        return randomNumberArray.reduce((res, arc) => {
            return [
                ...res, 
                {
                    lat: points[arc[0]][1],
                    lng: points[arc[0]][0],
                    size: 0.01,
                    color: POINT_COLOR
                },
                {
                    lat: points[arc[1]][1],
                    lng: points[arc[1]][0],
                    size: 0.01,
                    color: POINT_COLOR
                }
            ]
        }, [])
    }, [points, randomNumberArray])

    const ref = useRef(null)

    useEffect(() => {
        ref.current.controls().autoRotate = true
        ref.current.controls().autoRotateSpeed = 0.5
    }, [ref])

    return <div>
        <div className="scroll-block" style={{
            position: 'absolute',
            width,
            height,
            zIndex: 2
        }}/>
        <div id="globeViz" />
        <Globe
            ref={ref}

            width={width}
            height={height}
            backgroundColor={'#00000000'}
            globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"

            pointsData={ringsData}
            pointAltitude="size"
            pointRadius={0.5}
            pointColor={"color"}

            arcsData={arcsData}
            arcStroke={0.9}
            arcColor={'color'}
            arcDashLength={() => Math.random() * 0.5 + 1}
            arcDashGap={() => Math.random() * 2 + 2}
            arcDashAnimateTime={() => Math.random() * 4000 + 2000}

            hexPolygonsData={countries.features}
            hexPolygonResolution={3}
            hexPolygonMargin={0.4}
            hexPolygonColor={() => POLYGON_COLOR}
        
            atmosphereAltitude={0.2}
        />
    </div>;
}