import React, { useEffect, useState } from "react";
import CommunitySection1 from "../components/community/CommunitySection1";
import CommunitySection2 from "../components/community/CommunitySection2";
import CommunitySection3 from "../components/community/CommunitySection3";
import CommunitySection4 from "../components/community/CommunitySection4";
import PageHeader from "../molcules/PageHeader";
import "./Community.scss";

function Community(props) {

  const [renderAfter, setRenderAfter] = useState(false)

  useEffect(() => {
    setTimeout(() => { setRenderAfter(true) }, 100)
  }, [])
  return (
    <div id={"community"}>
      <PageHeader prefix={"community"} />
      <CommunitySection1 />
      {renderAfter && <>
        <CommunitySection2 />
        <CommunitySection3 />
        <CommunitySection4 />
      </>}

    </div>
  );
}

export default Community;
