import React from "react";
import "./Recruit.scss";
import useTranslationCustom from "../assets/image/community-section-1/useTranslationCustom";
import { Link } from "react-router-dom";
import arrowIcon from "../assets/image/team-culture-section-2/right_arrow.png";

const Recruit = () => {
  const { t } = useTranslationCustom({
    prefix: "",
    nameSpace: "recruit",
  });

  return (
    <div className="recruit">
      <h1 className="recruit-title">{t("title")}</h1>
      <Link to={"/employment/recruit"} className="recruit-link">
        {t("button")}
        <img src={arrowIcon} alt="" className="recruit-link-arrow" />
      </Link>
    </div>
  );
};

export default Recruit;
