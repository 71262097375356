import React from "react";
import "./EmploymentRecruitDetail.scss";
import EmploymentRecruitContent from "../components/employmentRecruit/EmploymentRecruitContent";

function EmploymentRecruitDetail(props) {
  return (
    <>
      <EmploymentRecruitContent />
    </>
  );
}

export default EmploymentRecruitDetail;
