import React, { useCallback, useEffect, useRef, useState } from "react";
import "./NumberAnimationView.scss";

function NumberAnimationView({
  number,
  unit,
  numberClassName,
  unitClassName,
  divideNum = 100,
  intervalTime = 10,
}) {


  const ref = useRef(null);
  const [isAnimated, setIsAnimated] = useState(false);
  const [showNumber, setShowNumber] = useState(0);

  const onInteract = useCallback(async ([entry], observer) => {
    if (entry.isIntersecting && !isAnimated) {
      setIsAnimated(true)
      const interval = setInterval(() => {
        setShowNumber(prev => {
          if(prev < number){
            return parseInt(prev + number / divideNum)
          }
          return prev
        })
      }, intervalTime)
      setTimeout(() => {
        clearInterval(interval)
      }, divideNum * intervalTime * 2)
    }
  }, [isAnimated, number, divideNum, intervalTime]);

  useEffect(() => {
    let observer;
    observer = new IntersectionObserver(onInteract);
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => observer && observer.disconnect();
  }, [ref, onInteract]);

  return <p ref={ref}>
    <span className={numberClassName}>{showNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}&nbsp;</span>
    <span className={unitClassName}>{unit}</span>
  </p>
}

export default NumberAnimationView;
