import {employmentServer} from './utils/serverInstances';
import {makeQuery} from './utils/makeQuery';

const getRecruits = async(
    request: GetRecruitsRequest
): Promise<GetRecruitsResponse> =>
await employmentServer.get({
    path: `api/v1/employments/${request.language}`,
    // path: `api/v1/employments/eng`,
    query: makeQuery(request.query)
})
type GetRecruitsRequest = {
    language: string;
    query: GetRecruitsQuery;
}
type GetRecruitsQuery = {
limit:number;
offset?:number;
lastId?:number;
}
export type GetRecruitsResponse = {
    count: number;
    employments: Employment[]
}
type Employment = {
employment_id:number;
position:string;
type:string;
company:string;
team_intro:string[];
work_intro:string[];
preference:string[];
spec:string[];
comment:string[];
}

const getRecruitById = async(
    request: getNewsByIdRequest
): Promise<Employment> =>
await employmentServer.get({
    path: `api/v1/employments/${request.language}/${request.params.employmentId}`,
    // path: `api/v1/employments/kor/${request.params.employmentId}`,
})
type getNewsByIdRequest = {
    language:string;
    params: {
        employmentId: number
    };
}
export {
    getRecruits as getRecruitsFromServer,
    getRecruitById as getRecruitByIdFromServer
}
