import React, {useEffect} from "react";
import ServiceSection1 from "../components/service/ServiceSection1";
import ServiceSection2 from "../components/service/ServiceSection2";
import PageHeader from "../molcules/PageHeader";
import "./Service.scss";

function Service() {
  return (
    <div id={'service'}>
        <PageHeader prefix={"service"} />
        <ServiceSection1 />
        <ServiceSection2 />
    </div>
  );
}

export default Service;
