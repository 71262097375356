import React from "react";
import "./BrandSection3.scss";
import useTranslationCustom from "view/assets/image/community-section-1/useTranslationCustom";
import useScrollAnimation from "view/module/animation/useScrollAnimation";

const BRAND_GUIDE = [
  {
    language: "ko",
    file: process.env.REACT_APP_EQBR_CI_BRAND_GUIDE_KO,
  },
  {
    language: "en",
    file: process.env.REACT_APP_EQBR_CI_BRAND_GUIDE_EN,
  },
];

const BrandSection3 = () => {
  const { t: brandSection3Text, i18n } = useTranslationCustom({
    prefix: "section3",
    nameSpace: "brand",
  });
  useScrollAnimation(["brand-section-3"]);

  const moreButtonClickHandler = () => {
    try {
      const currentLanguage = i18n.language;
      window.open(BRAND_GUIDE.find((el) => el.language === currentLanguage).file);
    } catch (e) {
      console.error("File not found");
    }
  };

  return (
    <div className="brand-section-3 container">
      <div className="brand-section-3-body">
        <div className="brand-section-3-body-title">{brandSection3Text("title")}</div>
        <div className="brand-section-3-body-button" onClick={moreButtonClickHandler}>
          {brandSection3Text("button")}
        </div>
      </div>
    </div>
  );
};

export default BrandSection3;
