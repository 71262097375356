import React, { useState, useEffect } from "react";
import "./EmploymentRecruitMain.scss";
import { useNavigate } from "react-router-dom";
import { getEmploymentsAction } from "../../../action/employmentAction";
import { useResponsive } from "view/styles/Responsive";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";

function EmploymentRecruitMain(props) {
  const limit = 20;
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const [recruitsData, setRecruitsData] = useState();
  const getRecruits = async (selectedLanguage) => {
    const { result, error } = await getEmploymentsAction(selectedLanguage, limit);
    if (error) {
      return;
    }
    setRecruitsData(result.employments);
  };
  const { t, i18n } = useTranslationCustom({
    prefix: "",
    nameSpace: "recruit_list",
  });

  let languages = i18n.resolvedLanguage;
  const [selectedLanguage, setSelectedLanguage] = useState();
  useEffect(() => {
    setSelectedLanguage(languages);
  }, [languages]);

  useEffect(() => {
    if (selectedLanguage) {
      getRecruits(selectedLanguage);
    }
  }, [selectedLanguage]);

  const goTODetail = (employmentId) => {
    navigate(`/employment/recruit/${employmentId}`);
  };
  return (
    <div className="employment-recruit-section-1 container">
      <div className="employment-recruit-section-1-content">
        {!isMobile &&
          recruitsData?.map((el, index) => {
            return (
              <div className="employment-recruit-section-1-content-wrapper" key={`recruit-content-${index}`}>
                <div className="employment-recruit-section-1-content-item" key={index}>
                  <div className="employment-recruit-section-1-content-item-name">{el.position}</div>
                  <div className="employment-recruit-section-1-content-item-job">{el.group.split(",").join(` ・ `)}</div>
                  <div className="employment-recruit-section-1-content-item-join">
                    <button onClick={() => goTODetail(el.employment_id)} className="employment-recruit-section-1-content-item-join-button">
                      {t("button")}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        {isMobile &&
          recruitsData?.map((el, index) => {
            return (
              <div className="employment-recruit-section-1-content-wrapper" key={`mobile-recruit-content-${index}`}>
                <div onClick={() => goTODetail(el.employment_id)} className="employment-recruit-section-1-content-item mobile" key={index}>
                  <div className="employment-recruit-section-1-content-item-name">{el.position}</div>
                  <div className="employment-recruit-section-1-content-item-job">{el.group.split(",").join(` ・ `)}</div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default EmploymentRecruitMain;
