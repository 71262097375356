import React, { useEffect, useState } from "react";
import './Introduction.scss'
import PageHeader from "../molcules/PageHeader";
import IntroductionSection1 from "../components/introduction/IntroductionSection1";
import IntroductionSection2 from "../components/introduction/IntroductionSection2";
import IntroductionSection3 from "../components/introduction/IntroductionSection4";
import IntroductionSection4 from "../components/introduction/IntroductionSection3";

function Introduction(props) {

    const [renderAfter, setRenderAfter] = useState(false)

    useEffect(() => {
        setTimeout(() => {setRenderAfter(true)}, 50)
    }, [])
    
    return (
        <div id='introduction'>
            <PageHeader prefix={"introduction"}/>
            <IntroductionSection1/>
            {
                renderAfter && <>
                    <IntroductionSection2/>
                    <IntroductionSection3/>
                    <IntroductionSection4/>
                </>
            }
        </div>
    );
}

export default Introduction;
