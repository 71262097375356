import React, { useMemo, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./EmploymentRecruitContent.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import arrow from "../../assets/image/newsroom/arrow.svg";
import address from "../../assets/image/recruit-content/recruit-content-comapny-address.png";
import { getEmploymentByIdAction } from "../../../action/employmentAction";
import { useResponsive } from "view/styles/Responsive";

function EmploymentRecruitContent() {
  const [recruitData, setRecruitData] = useState();
  const { isDesktop } = useResponsive();
  const params = useParams();
  const recruitId = useMemo(() => Number(params.recruitId), [params]);
  const { t: recruitText, i18n } = useTranslationCustom({
    prefix: "",
    nameSpace: "recruit_detail",
  });
  let languages = i18n.resolvedLanguage;
  const [selectedLanguage, setSelectedLanguage] = useState();
  useEffect(() => {
    setSelectedLanguage(languages);
  }, [languages]);

  const getRecruitDetail = async (selectedLanguage) => {
    const { result, error } = await getEmploymentByIdAction(selectedLanguage, recruitId);
    if (error) {
      return;
    }
    setRecruitData(result);
  };
  useEffect(() => {
    if (selectedLanguage) {
      getRecruitDetail(selectedLanguage);
    }
  }, [selectedLanguage]);

  const apply = () => {
    window.open(
      `https://docs.google.com/forms/d/e/1FAIpQLSfrFvZajkVMtNqdVFGqZFghNqUPvkV-KtyeSp6KZWsEOJ_9Ww/viewform?usp=pp_url&entry.829311901=${recruitData?.company}&entry.771480914=${recruitData?.type}&entry.348022617=${recruitData?.position}`
    );
  };

  const searchMap = () => {
    window.open(
      "https://www.google.com/maps/place/%EC%84%9C%EC%A0%95%EB%B9%8C%EB%94%A9/data=!3m1!4b1!4m6!3m5!1s0x357ca40b8cb680a3:0x5571c39f622cf08!8m2!3d37.5109039!4d127.0449923!16s%2Fg%2F1v82_0tb?entry=ttu"
    );
  };

  return (
    <div className="employment-recruit-detail-content container">
      <div className="FLEX_ROW SPACE_BETWEEN">
        <div style={isDesktop ? { marginRight: 20 } : { marginRight: 0 }}>
          <div className="employment-recruit-detail-content-tap FLEX_ROW ALIGN_CENTER">
            <div>{recruitText("tab.employment")}</div>
            <img className={"employment-recruit-detail-content-tap-arrow"} src={arrow} alt={">"} />
            <div>{recruitText("tab.recruit")}</div>
          </div>
          <div className="employment-recruit-detail-content-title">{recruitData?.position}</div>
          <div className="employment-recruit-detail-content-item">
            <div className="employment-recruit-detail-content-item-title">{recruitText("content.team_intro")}</div>
            {recruitData?.team_intro.map((el, index) => {
              return (
                <div className="employment-recruit-detail-content-item-description" key={`team-intro-index-${index}`}>
                  • &nbsp;{el}
                </div>
              );
            })}
          </div>
          <div className="employment-recruit-detail-content-item">
            <div className="employment-recruit-detail-content-item-title">{recruitText("content.work_intro")}</div>
            {recruitData?.work_intro.map((el, index) => {
              return (
                <div className="employment-recruit-detail-content-item-description" key={`work-intro-index-${index}`}>
                  • &nbsp;{el}
                </div>
              );
            })}
          </div>
          <div className="employment-recruit-detail-content-item">
            <div className="employment-recruit-detail-content-item-title">{recruitText("content.preference")}</div>
            {recruitData?.preference.map((el, index) => {
              return (
                <div className="employment-recruit-detail-content-item-description" key={`perference-${index}`}>
                  • &nbsp;{el}
                </div>
              );
            })}
          </div>
          <div className="employment-recruit-detail-content-item">
            <div className="employment-recruit-detail-content-item-title">{recruitText("content.spec")}</div>
            {recruitData?.spec.map((el, index) => {
              return (
                <div className="employment-recruit-detail-content-item-description" key={`spec-${index}`}>
                  • &nbsp;{el}
                </div>
              );
            })}
          </div>
          <div className="employment-recruit-detail-content-item">
            <div className="employment-recruit-detail-content-item-title">{recruitText("content.process.title")}</div>
            <div className="employment-recruit-detail-content-item-description">• &nbsp;{recruitText("content.process.description")}</div>
          </div>
          <div className="employment-recruit-detail-content-item">
            <div className="employment-recruit-detail-content-item-title">{recruitText("content.comment")}</div>
            {recruitData?.comment.map((el, index) => {
              return (
                <div className="employment-recruit-detail-content-item-description" key={`comment-${index}`}>
                  • &nbsp;{el}
                </div>
              );
            })}
          </div>
          {!isDesktop && (
            <div className="employment-recruit-detail-content-right-box">
              <div className="employment-recruit-detail-content-right-box-name">
                <div className="employment-recruit-detail-content-right-box-name-key">{recruitText("content.name")}</div>
                <div className="employment-recruit-detail-content-right-box-name-value">{recruitData?.position}</div>
              </div>
              <div className="employment-recruit-detail-content-right-box-line" />
              <div className="employment-recruit-detail-content-right-box-work">
                <div className="employment-recruit-detail-content-right-box-work-key">{recruitText("content.work")}</div>
                <div className="employment-recruit-detail-content-right-box-work-value">{recruitData?.group.split(",").join(` ・ `)}</div>
              </div>
              <div className="employment-recruit-detail-content-right-box-line" />
              <div className="employment-recruit-detail-content-right-box-work-place">
                <div className="employment-recruit-detail-content-right-box-work-place-info">
                  <div className="employment-recruit-detail-content-right-box-work-place-info-key">{recruitText("content.work-place")}</div>
                  <div className="employment-recruit-detail-content-right-box-work-place-info-value" onClick={() => searchMap()}>
                    {recruitText("content.address")}
                  </div>
                </div>
                <img src={address} alt="address" onClick={() => searchMap()} className="employment-recruit-detail-content-right-box-work-place-image" />
              </div>
              <div className="employment-recruit-detail-content-right-box-btn" onClick={apply}>
                {recruitText("content.button")}
              </div>
            </div>
          )}
          <Link to={"/employment/recruit"}>
            <div className="employment-recruit-detail-content-btn"> {recruitText("to_list")}</div>
          </Link>
        </div>
        <div style={{ flex: 1 }}>
          {isDesktop && (
            <div className="employment-recruit-detail-content-right">
              <div className="employment-recruit-detail-content-right-box">
                <div className="employment-recruit-detail-content-right-box-name">
                  <div className="employment-recruit-detail-content-right-box-name-key">{recruitText("content.name")}</div>
                  <div className="employment-recruit-detail-content-right-box-name-value">{recruitData?.position}</div>
                </div>
                <div className="employment-recruit-detail-content-right-box-line" />
                <div className="employment-recruit-detail-content-right-box-work">
                  <div className="employment-recruit-detail-content-right-box-work-key">{recruitText("content.work")}</div>
                  <div className="employment-recruit-detail-content-right-box-work-value">{recruitData?.group.split(",").join(` ・ `)}</div>
                </div>
                <div className="employment-recruit-detail-content-right-box-line" />
                <div className="employment-recruit-detail-content-right-box-work-place">
                  <div className="employment-recruit-detail-content-right-box-work-place-info">
                    <div className="employment-recruit-detail-content-right-box-work-place-info-key">{recruitText("content.work-place")}</div>
                    <div className="employment-recruit-detail-content-right-box-work-place-info-value" onClick={() => searchMap()}>
                      {recruitText("content.address")}
                    </div>
                  </div>
                  <img src={address} alt="address" onClick={() => searchMap()} className="employment-recruit-detail-content-right-box-work-place-image" />
                </div>
                <div className="employment-recruit-detail-content-right-box-btn" onClick={apply}>
                  {recruitText("content.button")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmploymentRecruitContent;
