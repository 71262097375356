import React from "react";
import "./view/styles/reset.scss";
import "./view/styles/containers.scss";
import "./view/styles/fonts.scss";
import "./view/styles/common.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { BrowserRouter } from "react-router-dom";
import initAnimation from "./view/module/animation";
import { RootRouter } from "./view/router/RootRouter";
import { Toaster } from "react-hot-toast";
import './locales/index';

initAnimation();

function App() {
  return (
    <BrowserRouter>
      <RootRouter />
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
