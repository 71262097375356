import React from "react";
import "./SectionDownButton.scss";

function SectionDownButton({ fullpageApi }) {
  return (
    <button className="section-down-button" onClick={() => fullpageApi.moveSectionDown()}>
      <span />
    </button>
  );
}

export default SectionDownButton;
