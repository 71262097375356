import React, { useMemo } from "react";
import "./CommunitySection3.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import useScrollAnimation from "../../module/animation/useScrollAnimation";
import partner01 from "../../assets/image/home-section-4/01.png";
import partner02 from "../../assets/image/home-section-4/02.png";
import partner03 from "../../assets/image/home-section-4/03.png";
import partner04 from "../../assets/image/home-section-4/04.png";
import partner05 from "../../assets/image/home-section-4/05.png";
import partner06 from "../../assets/image/home-section-4/06.png";
import partner07 from "../../assets/image/home-section-4/07.png";
import partner08 from "../../assets/image/home-section-4/08.png";
import partner09 from "../../assets/image/home-section-4/09.png";
import partner10 from "../../assets/image/home-section-4/10.png";
import partner11 from "../../assets/image/home-section-4/11.png";
import partner12 from "../../assets/image/home-section-4/12.png";
import partner13 from "../../assets/image/home-section-4/13.png";
import partner14 from "../../assets/image/home-section-4/14.png";
import partner15 from "../../assets/image/home-section-4/15.png";
import partner16 from "../../assets/image/home-section-4/16.png";
import partner17 from "../../assets/image/home-section-4/17.png";
import partner18 from "../../assets/image/home-section-4/18.png";
import partner19 from "../../assets/image/home-section-4/19.png";
import partner20 from "../../assets/image/home-section-4/20.png";
import partner21 from "../../assets/image/home-section-4/21.png";

const partners = [
  partner01,
  partner02,
  partner03,
  partner04,
  partner05,
  partner06,
  partner07,
  partner08,
  partner09,
  partner10,
  partner11,
  partner12,
  partner13,
  partner14,
  partner15,
  partner16,
  partner17,
  partner18,
  partner19,
  partner20,
  partner21,
];

function CommunitySection3() {
  useScrollAnimation(["community-section-3"]);

  const { t } = useTranslationCustom({
    prefix: "section_3",
    nameSpace: "community",
  });

  return (
    <div className={"container community-section-3"}>
      <div className="community-section-3-title">{t("title")}</div>
      <div className="community-section-3-sub-title">{t("sub_title")}</div>
      <div className="community-section-3-partners">
        {partners.map((el, index) => (
          <div className="community-section-3-partners-item" key={`partner-${index}`}>
            <img src={el} alt="" key={`front-img-${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CommunitySection3;
