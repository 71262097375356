import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Layout } from "./Layout";
import Home from "../page/Home";
import NotFound from "../components/NotFound";
import Community from "../page/Community";
import Introduction from "../page/Introduction";
import Tech from "../page/Tech";
import Service from "../page/Service";
import NewsRoom from "../page/NewsRoom";
import NewsRoomDetail from "../page/NewsRoomDetail";
import TeamCulture from "../page/TeamCulture";
import EmploymentRecruit from "../page/EmploymentRecruit";
import EmploymentRecruitDetail from "../page/EmploymentRecruitDetail";
import Brand from "../page/Brand";

export const RootRouter = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={"/"} element={<Home />} />
        <Route path={"eqbr/*"}>
          <Route path={"introduction/"} element={<Introduction />} />
          <Route path={"community/"} element={<Community />} />
          <Route path={"brand/"} element={<Brand />} />
        </Route>
        <Route path={"tech-service/*"}>
          <Route path={"tech"} element={<Tech />} />
          <Route path={"service"} element={<Service />} />
        </Route>
        <Route path={"employment/*"}>
          <Route path={"team-culture"} element={<TeamCulture />} />
          <Route path={"recruit/*"}>
            <Route index element={<EmploymentRecruit />} />
            <Route path={":recruitId"} element={<EmploymentRecruitDetail />} />
          </Route>
        </Route>
        <Route path={"newsroom/*"}>
          <Route index element={<NewsRoom />} />
          <Route path={":newsId"} element={<NewsRoomDetail />} />
        </Route>
        <Route path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  );
};
