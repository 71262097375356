import React from "react";
import "./CommunitySection2.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import purpleCard from "./../../assets/image/community-section-2/card-top-purple.png";
import orangeCard from "./../../assets/image/community-section-2/card-top-orange.png";
import linkButton from "./../../assets/image/community-section-2/card-button.png";
import useScrollAnimation from "../../module/animation/useScrollAnimation";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import APND from "../../assets/image/community-section-3/APND.png";
import KMAX from "../../assets/image/community-section-3/KMAX.png";

function CommunitySection2() {
  const { t } = useTranslationCustom({
    prefix: "section_2",
    nameSpace: "community",
  });
  useScrollAnimation(["community-section-2"]);
  const movePage = () => {
    toast("Coming soon!", {
      icon: "👋",
    });
  };
  return (
    <div className={"container community-section-2 FLEX_COLUMN_ALIGN_CENTER"}>
      <div className="community-section-2-title">{t("title")}</div>
      <div className="community-section-2-sub-title">{t("sub_title")}</div>
      <div className="community-section-2-content">
        <div className="community-section-2-content-card">
          <img src={purpleCard} alt="pulple-card" className="community-section-2-content-card-top" />
          <div className="community-section-2-content-card-content">
            <img className="community-section-2-content-card-content-title" src={APND} alt="apnd" />
            <div className="community-section-2-content-card-content-description">{t("apand.description")}</div>
            <Link className="community-section-2-content-card-content-link" to={"https://bondez.io/"} target="_blank">
              <div className="community-section-2-content-card-content-link-text">{t("button_text")}</div>
              <img src={linkButton} className="community-section-2-content-card-content-link-button" alt="button" />
            </Link>
          </div>
        </div>
        <div className="community-section-2-content-card">
          <img src={orangeCard} alt="orange-card" className="community-section-2-content-card-top" />
          <div className="community-section-2-content-card-content">
            <img className="community-section-2-content-card-content-title" src={KMAX} alt="kmax" />
            <div className="community-section-2-content-card-content-description">{t("kmax.description")}</div>
            <div className="community-section-2-content-card-content-link" onClick={movePage}>
              <div className="community-section-2-content-card-content-link-text orange">{t("button_text")}</div>
              <img src={linkButton} className="community-section-2-content-card-content-link-button" alt="button" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunitySection2;
