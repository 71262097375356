import React from 'react';
import NewsList from '../components/newsroom/NewsList';
import PageHeader from '../molcules/PageHeader';
import './NewsRoom.scss'

function NewsRoom() {

    return (
        <div id={'newsroom'}>
            <PageHeader prefix={"newsroom"}/>
            <NewsList />
        </div>
    )
}

export default NewsRoom;
