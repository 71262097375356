import "./NewsContent.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import arrow from "../../assets/image/newsroom/arrow.svg";
import NewsItem from "./NewsItem";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import facebook from "../../assets/image/newsroom/facebook.svg";
import twitter from "../../assets/image/newsroom/twitter.svg";
import link from "../../assets/image/newsroom/link.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import {
  getNewsAction,
  getNewsByIdAction,
  getRelativeNewsAction,
} from "action/newsAction";
import { useResponsive } from "view/styles/Responsive";

const RECENT_LIMIT = 3;
const RELATIVE_LIMIT = 4;

function NewsContent() {
  const params = useParams();
  const newsId = useMemo(() => Number(params.newsId), [params]);

  const { isDesktop } = useResponsive();

  const { t } = useTranslationCustom({ prefix: "", nameSpace: "newsroom" });

  const [content, setContent] = useState({});
  const [recents, setRecents] = useState([]);
  const [relatives, setRelatives] = useState([]);

  const getContent = useCallback(async () => {
    const { result, error } = await getNewsByIdAction(newsId);
    if (error) {
      return;
    }
    setContent(result);
  }, [newsId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const getRecents = useCallback(async () => {
    const { result, error } = await getNewsAction(RECENT_LIMIT);
    const { news, count } = result;
    setRecents(news);
  }, []);

  useEffect(() => {
    getRecents();
  }, [getRecents]);

  const getRelatives = useCallback(async () => {
    const { result, error } = await getRelativeNewsAction(
      newsId,
      isDesktop ? 3 : RELATIVE_LIMIT,
      content.hashtag
    );
    if (error) {
      return;
    }
    setRelatives(result);
  }, [newsId, content, isDesktop]);

  useEffect(() => {
    getRelatives();
  }, [getRelatives]);
  return (
    <div className="container">
      <div className="FLEX_ROW">
        <div className="news-content-main">
          <div className="FLEX_ROW ALIGN_CENTER">
            <div>{t("detail.newsroom")}</div>
            <img className={"news-content-arrow"} src={arrow} alt={">"} />
            <div>{t(`category.${content.category}`)}</div>
          </div>
          <div className="news-content-main-title">{content.title}</div>
          <img
            className="news-content-main-image"
            src={content.img_url}
            alt={content.title}
          />
          <div className="news-content-main-text">{content.text}</div>
          <div className="news-content-main-shares">
            <TwitterShareButton
              className="news-content-main-share"
              url={window.location.href}
            >
              <img
                className="news-content-main-share-icon"
                src={twitter}
                alt="twitter"
              />
            </TwitterShareButton>
            <FacebookShareButton
              className="news-content-main-share"
              url={window.location.href}
            >
              <img
                className="news-content-main-share-icon"
                src={facebook}
                alt="facebook"
              />
            </FacebookShareButton>
            <button
              className="news-content-main-share"
              onClick={() => window.open(content.link)}
            >
              <img
                className="news-content-main-share-icon"
                src={link}
                alt="link"
              />
            </button>
          </div>
          <div className="news-content-main-hashtags">
            {content.hashtag?.split(",").map((str) => (
              <span className="news-content-main-hashtag">#{str.trim()}</span>
            ))}
          </div>
          <Link className="news-content-main-to-list" to={"/newsroom"}>
            {t("detail.to_list")}
          </Link>
        </div>
        <div style={{ flex: 1 }}>
          <div className="news-content-recent">
            <div className="news-content-recent-title">
              {t("detail.recent_news")}
            </div>
            {recents.map((recent) => (
              <Link
                className="news-content-recent-item"
                to={`/newsroom/${recent.news_id}`}
              >
                <img
                  className="news-content-recent-item-image"
                  src={recent.img_url}
                  alt={"_"}
                />
                <div className="news-content-recent-item-text">
                  {recent.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      {relatives.length > 0 && (
        <div className="news-content-bottom">
          <p className="news-content-bottom-title">
            {t("detail.relative_content")}
          </p>
          <div className="news-content-relative-container">
            {relatives.map((relative) => (
              <NewsItem {...relative} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default NewsContent;
