import React, { useEffect, useState } from "react";
import PageHeader from "../molcules/PageHeader";
import "./TeamCulture.scss";
import TeamCultureSection1 from "../components/teamCulture/TeamCultureSection1";
import TeamCultureSection2 from "../components/teamCulture/TeamCultureSection2";
import TeamCultureSection3 from "../components/teamCulture/TeamCultureSection3";
import TeamCultureSection4 from "../components/teamCulture/TeamCultureSection4";

function TeamCulture(props) {

  const [renderAfter, setRenderAfter] = useState(false)

  useEffect(() => {
    setTimeout(() => { setRenderAfter(true) }, 100)
  }, [])

  return (
    <div id={'team-culture'}>
      <PageHeader prefix={"team_culture"} />
      <TeamCultureSection1 />
      {renderAfter && <>
        <TeamCultureSection2 />
        <TeamCultureSection3 />
        <TeamCultureSection4 />
      </>}

    </div>
  );
}

export default TeamCulture;
