import React from "react";
import "./TeamCultureSection4.scss";
import useScrollAnimation from "../../module/animation/useScrollAnimation";
import Recruit from "../Recruit";

function TeamCultureSection4() {
  useScrollAnimation(["team-culture-section-4"]);

  return (
    <div className={"team-culture-section-4"}>
      <Recruit />
    </div>
  );
}

export default TeamCultureSection4;
