import React from "react";
import "./IntroductionSection3.scss";
import YouTubeComponent from "../../molcules/YouTubeComponent";
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function IntroductionSection3() {
  useScrollAnimation(["introduction-section-3"]);

  return (
    <div className={"introduction-section-3 container"}>
      <div className="introduction-section-3-youtube">
        <YouTubeComponent
          className={"introduction-section-3-youtube-content"}
          videoId={"63vJ2bHmwaw"}
        />
      </div>
    </div>
  );
}

export default IntroductionSection3;
