import React, { useMemo, useState } from "react";
import "./AnimationDiv.scss";
import Tilt from "react-tilt";

function AnimationDiv({
  className,
  borderRadius = 26,
  backgroundColor = "#171B21",
  circleSize = 2000,
  color1 = "blue",
  children,
  useTilt = true,
  useGradient = true,
}) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [mouseOn, setMouseOn] = useState(false);
  const handleMouseMove = (event) => {
    setMouseOn(true);
    const { left, top } = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - left;
    const y = event.clientY - top;
    setMousePosition({ x, y });
  };

  const handleMouseOut = () => {
    setMousePosition({ x: 0, y: 0 });
    setMouseOn(false);
  };
  const boxStyle = useMemo(
    () => ({
      borderRadius,
      backgroundColor,
    }),
    [borderRadius, backgroundColor]
  );

  const circleStyle = useMemo(
    () => ({
      width: circleSize,
      height: circleSize,
      background: `radial-gradient(${color1}, #00000000, #00000000, #000000)`,
      top: mousePosition.y,
      left: mousePosition.x,
    }),
    [circleSize, mousePosition, color1]
  );

  if (useTilt) {
    return (
      <Tilt
        options={{ scale: 1, max: 5 }}
        style={boxStyle}
        className={`tilt-component${className ? ` ${className}` : ""}`}
        {...(useGradient && {
          onMouseMove: handleMouseMove,
          onMouseLeave: handleMouseOut,
        })}
        // onMouseMove={handleMouseMove}
        // onMouseLeave={handleMouseOut}
      >
        <div className="tilt-component-container">{children}</div>
        {useGradient && mouseOn ? (
          <div style={circleStyle} className="tilt-component-hover-gradient" />
        ) : (
          <></>
        )}
      </Tilt>
    );
  } else {
    return (
      <div
        className={`tilt-component${className ? ` ${className}` : ""}`}
        style={boxStyle}
        {...(useGradient && {
          onMouseMove: handleMouseMove,
          onMouseLeave: handleMouseOut,
        })}
      >
        <div className="tilt-component-container">{children}</div>
        {useGradient && mouseOn ? (
          <div style={circleStyle} className="tilt-component-hover-gradient" />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default AnimationDiv;
