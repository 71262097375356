import React, { useMemo } from "react";
import "./TeamCultureSection3.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function TeamCultureSection3() {
  const { t, i18n } = useTranslationCustom({
    prefix: "section_3",
    nameSpace: "team_culture",
  });
  useScrollAnimation(["team-culture-section-3"]);

  return (
    <div className={"team-culture-section-3 container"}>
      <h1 className={"title"}>{t("title")}</h1>
      <div className={"desc-container"}>
        {t("descList", { returnObjects: true }).map((el, index) => (
          <div className={"desc-wrapper"} key={`d_${index}`}>
            <h2 className={"desc-title"}>{el.title}</h2>
            <p className={"desc"}>{el.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TeamCultureSection3;
