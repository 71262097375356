import React from "react";
import "./BrandSection2.scss";
import useScrollAnimation from "view/module/animation/useScrollAnimation";
import symbol from "../../assets/image/brand/brand-symbol.png";
import textLogo from "../../assets/image/brand/brand-text-logo.png";
import useTranslationCustom from "view/assets/image/community-section-1/useTranslationCustom";

// CI Concept
const BrandSection2 = () => {
  const { t: brandSection2Text } = useTranslationCustom({
    prefix: "section2",
    nameSpace: "brand",
  });

  useScrollAnimation(["brand-section-2"]);

  return (
    <div className="brand-section-2 container">
      <div className="brand-section-2-title">CI Concept</div>
      <div className="brand-section-2-concept-wrapper">
        <div className="brand-section-2-concept">
          <img className="brand-section-2-concept-image symbol" src={symbol} alt="" />
          <div className="brand-section-2-concept-introduction">
            <span className="brand-section-2-concept-introduction-title">{brandSection2Text("introduction_symbol_title")}</span>
            <span className="brand-section-2-concept-introduction-description">{brandSection2Text("introduction_symbol_description")}</span>
          </div>
        </div>
        <div className="brand-section-2-concept">
          <img className="brand-section-2-concept-image text-logo" src={textLogo} alt="" />
          <div className="brand-section-2-concept-introduction">
            <span className="brand-section-2-concept-introduction-title">{brandSection2Text("introduction_text_logo_title")}</span>
            <span className="brand-section-2-concept-introduction-description">{brandSection2Text("introduction_text_logo_description")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandSection2;
