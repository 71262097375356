import React from "react";
import "./ServiceSection1.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import TransCustom from "../../../locales/TransCustom";
import hubImage1 from "./../../assets/image/service-section-1/service-section-1-eqhub1.png";
import hubImage2 from "./../../assets/image/service-section-1/service-section-1-eqhub2.png";

function ServiceSection1(props) {
  const { t: section1Text, i18n } = useTranslationCustom({
    prefix: "section_1",
    nameSpace: "service",
  });

  return (
    <div className="service-section-1 container">
      <div id="EQHub" className="service-section-1-title">
        {section1Text("title")}
      </div>
      <div className="service-section-1-sub-title">
        {section1Text("description1")}
      </div>
      <div className="service-section-1-img">
        <img
          src={hubImage1}
          alt="eqhubImage-1"
          className="service-section-1-img-1"
        />
        <img
          src={hubImage2}
          alt="eqhubImage-2"
          className="service-section-1-img-2"
        />
      </div>
      <div className="service-section-1-text">
        <TransCustom nameSpace={"service"} prefix={"section_1.description2"}>
          _
          <span className="service-section-1-hilight-text" />
        </TransCustom>
      </div>
      <div
        className="service-section-1-button"
        onClick={() => window.open(process.env.REACT_APP_EQHUB_URL)}
      >
        {section1Text("link_text")}
      </div>
    </div>
  );
}

export default ServiceSection1;
