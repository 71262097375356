import React, { useState, useMemo, useEffect } from "react";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import "./CommunitySection1.scss";
import AnimationDiv from "../../molcules/AnimationDiv";
import worldMapLarge from "./../../assets/image/community-section-1/community-section-1-worldmap-large.png";
import worldMapSmall from "./../../assets/image/community-section-1/community-section-1-worldmap-small.png";
import LinkButton from "../../molcules/LinkButton";
import { useMediaQuery } from "react-responsive";
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function CommunitySection1() {
  const isExtraLarge = useMediaQuery({ query: "(min-width: 1280px)" });
  const { t, i18n } = useTranslationCustom({
    prefix: "section_1",
    nameSpace: "community",
  });
  let languages = i18n.resolvedLanguage;
  const [selectedLanguage, setSelectedLanguage] = useState();
  useEffect(() => {
    setSelectedLanguage(languages);
  }, [languages]);

  // useEffect(() => {
  // if (selectedLanguage) {
  // getRecruits(selectedLanguage);
  // }
  // }, [selectedLanguage]);
  const companys = useMemo(() => {
    return [
      {
        id: "eqbr_holdings",
        name: t("company.eqbr_holdings.name"),
        region: t("company.eqbr_holdings.region"),
        description: t("company.eqbr_holdings.description"),
      },
      {
        id: "my_flex",
        name: t("company.my_flex.name"),
        region: t("company.my_flex.region"),
        description: t("company.my_flex.description"),
        link: "https://myflex.xyz",
      },
      {
        id: "eqbr_networks",
        name: t("company.eqbr_networks.name"),
        region: t("company.eqbr_networks.region"),
        description: t("company.eqbr_networks.description"),
        link: "none",
      },
    ];
  }, [t]);
  const [selectedCompany, setSelectedCompany] = useState(companys[0]);
  useScrollAnimation(["community-section-1"]);

  return (
    <div className="community-section-1">
      <AnimationDiv className={"community-section-1-block"} useTilt={false} color1={"blue"} circleSize={500}>
        <div className="community-section-1-block-content">
          {isExtraLarge ? (
            <img src={worldMapLarge} className="community-section-1-block-content-img" alt="" />
          ) : (
            <img src={worldMapSmall} className="community-section-1-block-content-img" alt="" />
          )}
        </div>
      </AnimationDiv>
      {isExtraLarge && (
        <div className="community-section-1-company">
          <div className="community-section-1-company-top">
            <AnimationDiv className={selectedLanguage === "en" ? "community-section-1-company-top-box en" : "community-section-1-company-top-box"}>
              <div className="community-section-1-company-top-box-block">
                <div className="community-section-1-company-top-box-block-name">{t("company.eqbr_holdings.name")}</div>
                <div className="community-section-1-company-top-box-block-region">{t("company.eqbr_holdings.region")}</div>
                <div className="community-section-1-company-top-box-block-description">{t("company.eqbr_holdings.description")}</div>
              </div>
            </AnimationDiv>
            <div className="community-section-1-company-middle">
              <div className="community-section-1-company-middle-button-left">
                <div className="waves wave-1"></div>
                <div className="waves wave-2"></div>
                <div className="waves wave-3"></div>
              </div>
              <div className="community-section-1-company-middle-button">
                <div className="waves wave-1"></div>
                <div className="waves wave-2"></div>
                <div className="waves wave-3"></div>
              </div>
            </div>
            <div className="community-section-1-company-top-right">
              <div className="community-section-1-company-top-right-button" />
            </div>
          </div>
          <div className="community-section-1-company-bottom">
            <div className="community-section-1-company-bottom-left">
              <div className="community-section-1-company-bottom-left-button">
                <div className="waves wave-1"></div>
                <div className="waves wave-2"></div>
                <div className="waves wave-3"></div>
              </div>
              <AnimationDiv className={"community-section-1-company-bottom-left-box"}>
                <div className="community-section-1-company-bottom-left-box-block">
                  <div className="community-section-1-company-bottom-left-box-block-name">{t("company.eqbr_networks.name")}</div>
                  <div className="community-section-1-company-bottom-left-box-block-region">{t("company.eqbr_networks.region")}</div>
                  <div className="community-section-1-company-bottom-left-box-block-description">{t("company.eqbr_networks.description")}</div>
                  <LinkButton
                    // to={"/tech-service/service#EQHub"}
                    style={{ cursor: "default" }}
                    text={t("company.eqbr_networks.link")}
                    className={"community-section-1-company-bottom-left-box-block-button"}
                  />
                </div>
              </AnimationDiv>
            </div>
            <AnimationDiv className={"community-section-1-company-bottom-right-box"}>
              <div className="community-section-1-company-bottom-right-box-block">
                <div className="community-section-1-company-bottom-right-box-block-name">{t("company.my_flex.name")}</div>
                <div className="community-section-1-company-bottom-right-box-block-region">{t("company.my_flex.region")}</div>
                <div className="community-section-1-company-bottom-right-box-block-description">{t("company.my_flex.description")}</div>
                <LinkButton
                  to={"https://myflex.xyz"}
                  target={"_blank"}
                  text={t("company.my_flex.link")}
                  className={"community-section-1-company-bottom-right-box-block-button"}
                />
              </div>
            </AnimationDiv>
          </div>
        </div>
      )}
      {!isExtraLarge && (
        <>
          <div className="community-section-1-button container">
            <div className="community-section-1-button-top">
              <div className="community-section-1-button-top-button1" onClick={() => setSelectedCompany(companys[1])}>
                {selectedCompany === companys[1] ? (
                  <>
                    <div className="waves wave-1"></div>
                    <div className="waves wave-2"></div>
                    <div className="waves wave-3"></div>
                  </>
                ) : (
                  <div className="button" />
                )}
              </div>
              <div className="community-section-1-button-top-button2" onClick={() => setSelectedCompany(companys[0])}>
                {selectedCompany === companys[0] ? (
                  <>
                    <div className="waves wave-1"></div>
                    <div className="waves wave-2"></div>
                    <div className="waves wave-3"></div>
                  </>
                ) : (
                  <div className="button" />
                )}
              </div>
            </div>
            <div className="community-section-1-button-bottom-button3" onClick={() => setSelectedCompany(companys[2])}>
              {selectedCompany === companys[2] ? (
                <>
                  <div className="waves wave-1"></div>
                  <div className="waves wave-2"></div>
                  <div className="waves wave-3"></div>
                </>
              ) : (
                <div className="button" />
              )}
            </div>
          </div>
          <div className="community-section-1-bottom-content">
            <div className="community-section-1-bottom-content-name">{selectedCompany.name}</div>
            <div className="community-section-1-bottom-content-region">{selectedCompany.region}</div>
            <div className="community-section-1-bottom-content-description">{selectedCompany.description}</div>
            {selectedCompany.link && (
              <LinkButton
                to={selectedCompany.link !== "none" && selectedCompany.link}
                target={"_blank"}
                text={selectedCompany.link === "none" ? "Comming soon " : "홈페이지 바로가기"}
                className={"community-section-1-bottom-content-link"}
                style={selectedCompany.link === "none" && { cursor: "default" }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
export default CommunitySection1;
