import React from "react";
import "./LinkButton.scss";
import linkArrow from "../assets/image/home-section-1/link-arrow.svg";

export default function LinkButton({ to, text, className, target }) {
  return (
    <a href={to} className={`link-button${className ? ` ${className}` : ""}`} target={target}>
      {text}
      <img src={linkArrow} alt="link" />
    </a>
  );
}
