import "./HomeSection2.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import image1 from "../../assets/image/home-section-2/home-section-2-image-1.png";
import image2 from "../../assets/image/home-section-2/home-section-2-image-2.png";
import TransCustom from "../../../locales/TransCustom";
import LinkButton from "../../molcules/LinkButton";
import { useEffect, useMemo, useState } from "react";
import { useResponsive } from "view/styles/Responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

function HomeSection2() {
  const { t } = useTranslationCustom({
    prefix: "section_2",
    nameSpace: "home",
  });

  const { isMobile, isTablet, isDesktop } = useResponsive();

  const contents = useMemo(
    () => [
      {
        image: image1,
        nameSpace: "home",
        prefix: "section_2.eq_hub_explain",
        to: "/tech-service/service#EQHub",
        linkText: t("link_eq_hub"),
      },
      {
        image: image2,
        nameSpace: "home",
        prefix: "section_2.whisper_explain",
        to: "/tech-service/service#Whisper",
        linkText: t("link_whisper"),
      },
    ],
    [t]
  );

  const [renderAfter, setRenderAfter] = useState(false)

  useEffect(() => {
    setTimeout(() => { setRenderAfter(true) }, 200)
  }, [])

  return (
    <div className={"home-section-2"}>
      {renderAfter && <div className="container home-section-2-container">
        <div className="home-section-2-title">{t("potential")}</div>
        {isDesktop && (
          <div className="home-section-2-flex">
            {contents.map((content, index) => (
              <div className="home-section-2-block" key={`k_${index}`}>
                <img
                  className="home-section-2-block-img"
                  src={content.image}
                  alt={"browser"}
                />
                <p className="home-section-2-block-text">
                  <TransCustom
                    nameSpace={content.nameSpace}
                    prefix={content.prefix}
                  >
                    _
                    <span className="home-section-2-hilight-text" />
                  </TransCustom>
                </p>
                <LinkButton
                  to={content.to}
                  text={content.linkText}
                  className={"home-section-2-block-link"}
                />
              </div>
            ))}
          </div>
        )}
        {isTablet && (
          <Swiper
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 1,
            }}
            speed={500}
            allowTouchMove={true}
            modules={[Navigation, Pagination]}
            className={"home-section-2-swiper container"}
          >
            {contents.map((content, index) => (
              <SwiperSlide key={`k_${index}`}>
                <div className="home-section-2-block">
                  <img
                    className="home-section-2-block-img"
                    src={content.image}
                    alt={"browser"}
                  />
                  <p className="home-section-2-block-text">
                    <TransCustom
                      nameSpace={content.nameSpace}
                      prefix={content.prefix}
                    >
                      _
                      <span className="home-section-2-hilight-text" />
                    </TransCustom>
                  </p>
                  <LinkButton
                    to={content.to}
                    text={content.linkText}
                    className={"home-section-2-block-link"}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {isMobile && (
          <Swiper
            pagination={{
              dynamicBullets: false,
              dynamicMainBullets: 1,
            }}
            speed={500}
            allowTouchMove={true}
            modules={[Navigation, Pagination]}
            spaceBetween={50}
            className={"home-section-2-swiper container"}
          >
            {contents.map((content, index) => (
              <SwiperSlide key={`k_${index}`}>
                <div className="home-section-2-block">
                  <img
                    className="home-section-2-block-img"
                    src={content.image}
                    alt={"browser"}
                  />
                  <p className="home-section-2-block-text">
                    <TransCustom
                      nameSpace={content.nameSpace}
                      prefix={content.prefix}
                    >
                      _
                      <span className="home-section-2-hilight-text" />
                    </TransCustom>
                  </p>
                  <LinkButton
                    to={content.to}
                    text={content.linkText}
                    className={"home-section-2-block-link"}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>}
    </div>
  );
}

export default HomeSection2;
