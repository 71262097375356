import React from "react";
import "./IntroductionSection1.scss";
import background_video from "../../assets/image/introduction-section-2/video.mp4";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function IntroductionSection1() {
  useScrollAnimation(["introduction-section-1"]);

  const { t } = useTranslationCustom({
    prefix: "section_1",
    nameSpace: "introduction",
  });

  return (
    <div className={"introduction-section-1 container"}>
      {/* <div className="video-wrapper"> */}
      <video src={background_video} autoPlay muted loop playsInline />
      <div className="introduction-section-1-content-container">
        <div className="video-title">{t("video_title")}</div>
        <div className="video-text">{t("video_text")}</div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default IntroductionSection1;
