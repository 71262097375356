import React from "react";
import "./TechSection2.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import r from "../../assets/image/tech-section-2/ㄱ.svg";
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function TechSection2() {
  const { t: techSection1Text } = useTranslationCustom({
    prefix: "section2",
    nameSpace: "tech",
  });
  useScrollAnimation(["tech-section-2"]);

  return (
    <div className={"tech-section-2 container"}>
      <img className="tech-section-2-r" src={r} alt={"ㄱ"} />
      <div>
        <div className="tech-section-2-mca">{techSection1Text("mca")}</div>
        <div className="tech-section-2-mca-explain">
          {techSection1Text("mca_explain")}
        </div>
      </div>
      <div className="tech-section-2-circle-container">
        <div className="tech-section-2-circle1">
          {techSection1Text("horizontal_expansion")}
        </div>
        <div className="tech-section-2-circle2">
          {techSection1Text("network_optimization_configuration")}
        </div>
      </div>
    </div>
  );
}

export default TechSection2;
