import React from "react";
import "./IntroductionSection2.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import plus from "../../assets/image/introduction-section-2/plus.svg";
import useScrollAnimation from "../../module/animation/useScrollAnimation";
import { useMediaQuery } from "react-responsive";
const TABLET_MIN_WIDTH = 834;
const DESKTOP_MIN_WIDTH = 1280;
function IntroductionSection2() {
  const isTablet = useMediaQuery({
    query: `(min-width:${TABLET_MIN_WIDTH}px) and (max-width:${DESKTOP_MIN_WIDTH}px)`,
  });

  const { t } = useTranslationCustom({
    prefix: "section_2",
    nameSpace: "introduction",
  });
  useScrollAnimation(["introduction-section-2"]);

  return (
    <div className={"introduction-section-2"}>
      {isTablet ? (
        <>
          <div style={{ display: "flex" }}>
            <div className="box-wrapper first">
              <div className="box-title">{t("box_1_title")}</div>
              <img className="box-plus" src={plus} alt="plus" />
              <div className="box-text">{t("box_1_text")}</div>
            </div>
            <div className="box-wrapper second">
              <div className="box-title">{t("box_2_title")}</div>
              <img className="box-plus" src={plus} alt="plus" />
              <div className="box-text">{t("box_2_text")}</div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="box-wrapper third">
              <div className="box-title">{t("box_3_title")}</div>
              <img className="box-plus" src={plus} alt="plus" />
              <div className="box-text">{t("box_3_text")}</div>
            </div>
            <div className="box-wrapper fourth">
              <div className="box-title">{t("box_4_title")}</div>
              <img className="box-plus" src={plus} alt="plus" />
              <div className="box-text">{t("box_4_text")}</div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="box-wrapper first">
            <div className="box-title">{t("box_1_title")}</div>
            <img className="box-plus" src={plus} alt="plus" />
            <div className="box-text">{t("box_1_text")}</div>
          </div>
          <div className="box-wrapper second">
            <div className="box-title">{t("box_2_title")}</div>
            <img className="box-plus" src={plus} alt="plus" />
            <div className="box-text">{t("box_2_text")}</div>
          </div>
          <div className="box-wrapper third">
            <div className="box-title">{t("box_3_title")}</div>
            <img className="box-plus" src={plus} alt="plus" />
            <div className="box-text">{t("box_3_text")}</div>
          </div>
          <div className="box-wrapper fourth">
            <div className="box-title">{t("box_4_title")}</div>
            <img className="box-plus" src={plus} alt="plus" />
            <div className="box-text">{t("box_4_text")}</div>
          </div>
        </>
      )}
    </div>
  );
}

export default IntroductionSection2;
