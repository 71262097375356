import React from "react";
import "./TeamCultureSection1.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import team_image_1 from "../../assets/image/team-culture-section-1/1.jpg";
import team_image_2 from "../../assets/image/team-culture-section-1/2.jpg";
import team_image_3 from "../../assets/image/team-culture-section-1/3.jpg";
import team_image_4 from "../../assets/image/team-culture-section-1/4.jpg";
import team_image_5 from "../../assets/image/team-culture-section-1/5.jpg";
import team_image_6 from "../../assets/image/team-culture-section-1/6.jpg";
import team_image_7 from "../../assets/image/team-culture-section-1/7.jpg";
import team_image_8 from "../../assets/image/team-culture-section-1/8.jpg";
import team_image_9 from "../../assets/image/team-culture-section-1/9.jpg";
import team_image_10 from "../../assets/image/team-culture-section-1/10.jpg";

function TeamCultureSection1(props) {
  const { t: section1Text, i18n } = useTranslationCustom({
    prefix: "section_1",
    nameSpace: "team_culture",
  });
  const topImages = [
    team_image_1,
    team_image_2,
    team_image_3,
    team_image_4,
    team_image_5,
  ];

  const bottomImages = [
    team_image_6,
    team_image_7,
    team_image_8,
    team_image_9,
    team_image_10,
  ];

  return (
    <div className={"hidden-area"}>
      <div className="team-culture-section-1 container">
        <div className="title">{section1Text("title")}</div>
        <div className="image-flow">
          <div className="image-flow-row top">
            {topImages
              .concat(topImages)
              .concat(topImages)
              .map((image, index) => (
                <img src={image} key={`top_${index}`} alt={""} />
              ))}
          </div>
          <div className="image-flow-row bottom">
            {bottomImages
              .concat(bottomImages)
              .concat(bottomImages)
              .map((image, index) => (
                <img src={image} key={`top_${index}`} alt={""} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamCultureSection1;
