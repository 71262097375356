import React from "react";
import "./CommunitySection4.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import useScrollAnimation from "../../module/animation/useScrollAnimation";
const companyEmail = process.env.REACT_APP_COMPANY_EMAIL;

function CommunitySection4() {
  const { t } = useTranslationCustom({
    prefix: "section_4",
    nameSpace: "community",
  });

  useScrollAnimation(["community-section-4"]);

  return (
    <div
      id="Email"
      className={"community-section-4 container FLEX_COLUMN_ALIGN_CENTER"}
    >
      <div className="community-section-4-contact">
        <p className="community-section-4-contact-subtitle">
          {t("contact_subtitle")}
        </p>
        <p className="community-section-4-contact-title">
          {t("contact_title")}
        </p>
        <a
          className="community-section-4-contact-button FLEX_CENTER"
          href={`mailto:${companyEmail}`}
        >
          {t("contact_button")}
        </a>
      </div>
    </div>
  );
}

export default CommunitySection4;
