import React from "react";
import "./ServiceSection2.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import TransCustom from "../../../locales/TransCustom";
import topImage1 from "../../assets/image/service-section-2/service-section-2-top-1.png";
import topImage2 from "../../assets/image/service-section-2/service-section-2-top-2.png";
import topImage3 from "../../assets/image/service-section-2/service-section-2-top-3.png";
import topImage4 from "../../assets/image/service-section-2/service-section-2-top-4.png";
import bottomImage1 from "../../assets/image/service-section-2/service-section-2-bottom-1.png";
import bottomImage2 from "../../assets/image/service-section-2/service-section-2-bottom-2.png";
import AnimationDiv from "../../molcules/AnimationDiv";
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function ServiceSection2(props) {
  const { t: section2Text, i18n } = useTranslationCustom({
    prefix: "section_2",
    nameSpace: "service",
  });
  useScrollAnimation(["service-section-2"]);

  return (
    <div className="service-section-2 container">
      <div id="Whisper" className="service-section-2-title">
        {section2Text("title")}
      </div>
      <div className="service-section-2-sub-title">
        {section2Text("description")}
      </div>
      <div className="service-section-2-content-1">
        <div className="service-section-2-content-1-left">
          <div className="service-section-2-content-1-left-items-left">
            <AnimationDiv
              className={"service-section-2-content-1-left-items-left-item"}
              circleSize={1000}
            >
              <div className="service-section-2-content-1-left-items-left-item-block">
                <img
                  src={topImage1}
                  alt="section2TopImage1"
                  className="service-section-2-content-1-left-items-left-item-block-img"
                />
                <div className="service-section-2-content-1-left-items-left-item-block-title">
                  {section2Text("content.1.title")}
                </div>
                <div className="service-section-2-content-1-left-items-left-item-block-description">
                  {section2Text("content.1.description")}
                </div>
              </div>
            </AnimationDiv>
            <AnimationDiv
              className={"service-section-2-content-1-left-items-left-item"}
              circleSize={1000}
            >
              <div className="service-section-2-content-1-left-items-left-item-block">
                <img
                  src={topImage3}
                  alt="section2TopImage3"
                  className="service-section-2-content-1-left-items-left-item-block-img"
                />
                <div className="service-section-2-content-1-left-items-left-item-block-title">
                  {section2Text("content.3.title")}
                </div>
                <div className="service-section-2-content-1-left-items-left-item-block-description">
                  {section2Text("content.3.description")}
                </div>
              </div>
            </AnimationDiv>
          </div>

          <div className="service-section-2-content-1-left-items-right">
            <AnimationDiv
              className={"service-section-2-content-1-left-items-right-item"}
              circleSize={1000}
            >
              <div className="service-section-2-content-1-left-items-right-item-block">
                <img
                  src={topImage2}
                  alt="section2TopImage2"
                  className="service-section-2-content-1-left-items-right-item-block-img"
                />
                <div className="service-section-2-content-1-left-items-right-item-block-title">
                  {section2Text("content.2.title")}
                </div>
                <div className="service-section-2-content-1-left-items-right-item-block-description">
                  {section2Text("content.2.description")}
                </div>
              </div>
            </AnimationDiv>
            <AnimationDiv
              className={"service-section-2-content-1-left-items-right-item"}
              circleSize={1000}
            >
              <div className="service-section-2-content-1-left-items-right-item-block">
                <img
                  src={topImage4}
                  alt="section2TopImage4"
                  className="service-section-2-content-1-left-items-right-item-block-img"
                />
                <div className="service-section-2-content-1-left-items-right-item-block-title">
                  {section2Text("content.4.title")}
                </div>
                <div className="service-section-2-content-1-left-items-right-item-block-description">
                  {section2Text("content.4.description")}
                </div>
              </div>
            </AnimationDiv>
          </div>
        </div>
        <div className="service-section-2-content-1-right">
          <div className="service-section-2-content-1-right-title">
            {section2Text("whisper.name")}
          </div>
          <div className="service-section-2-content-1-right-description">
            <TransCustom
              nameSpace={"service"}
              prefix={"section_2.whisper.description1"}
            >
              _
              <span className="service-section-2-content-1-right-hilight-text" />
            </TransCustom>
          </div>
          <div
            className="service-section-2-content-1-right-button"
            onClick={() => window.open(process.env.REACT_APP_WHISPER_URL)}
          >
            {section2Text("whisper.link_text")}
          </div>
        </div>
      </div>
      <div className="service-section-2-content-2">
        <AnimationDiv className="service-section-2-content-2-item">
          <div className="service-section-2-content-2-item-block">
            <div className="service-section-2-content-2-item-block-text">
              <TransCustom
                nameSpace={"service"}
                prefix={"section_2.whisper.description2"}
              >
                _
                <span className="service-section-2-content-2-item-block-hilight-text" />
              </TransCustom>
            </div>
            <img
              src={bottomImage1}
              alt="section2BottomImage1"
              className="service-section-2-content-2-item-block-img-1"
            />
          </div>
        </AnimationDiv>
        <AnimationDiv className="service-section-2-content-2-item">
          <div className="service-section-2-content-2-item-block">
            <div className="service-section-2-content-2-item-block-text">
              <TransCustom
                nameSpace={"service"}
                prefix={"section_2.whisper.description3"}
              >
                _
                <span className="service-section-2-content-2-item-block-hilight-text" />
              </TransCustom>
            </div>
            <img
              src={bottomImage2}
              alt="section2BottomImage1"
              className="service-section-2-content-2-item-block-img-2"
            />
          </div>
        </AnimationDiv>
      </div>
    </div>
  );
}

export default ServiceSection2;
