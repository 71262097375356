import React from "react";
import NewsContent from "../components/newsroom/NewsContent";
import "./NewsRoomDetail.scss";

function NewsRoomDetail() {
  return (
    <div id={"newsroom-detail"} className={"newsroom-detail"}>
      <NewsContent />
    </div>
  );
}

export default NewsRoomDetail;
