import React from "react";
import "./IntroductionSection4.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function IntroductionSection4() {
  const { t } = useTranslationCustom({
    prefix: "section_4",
    nameSpace: "introduction",
  });
  useScrollAnimation(["introduction-section-4"]);

  return (
    <div className={"introduction-section-4 container"}>
      <div className="box-content-wrapper">
        <div className="box-title">{t("title")}</div>
        <div className="box-text">{t("text")}</div>
      </div>
    </div>
  );
}

export default IntroductionSection4;
