import React from "react";
import "./PageHeader.scss";
import useTranslationCustom from "../assets/image/community-section-1/useTranslationCustom";

function PageHeader({ prefix }) {
  const { t } = useTranslationCustom({
    prefix: prefix,
    nameSpace: "page_header",
  });

  return (
    <div className={"page-header container"}>
      <div className="title">{t("title")}</div>
      <div className="sub-title">{t("sub_title")}</div>
    </div>
  );
}

export default PageHeader;
