import "./TechSection1.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import r from "../../assets/image/tech-section-1/ㄱ.svg";
import TransCustom from "locales/TransCustom";
import NumberAnimationView from "../../molcules/NumberAnimationView";
import LinkButton from "view/molcules/LinkButton";

function TechSection1() {
  const { t: techSection1Text } = useTranslationCustom({
    prefix: "section1",
    nameSpace: "tech",
  });

  return (
    <div className={"tech-section-1 container"}>
      <div className="title">{techSection1Text("title")}</div>
      <img className="tech-section-1-r" src={r} alt={"ㄱ"} />
      <div className="tech-section-1-container">
        <div>
          <div className="tech-section-1-equalize">{techSection1Text("equalize")}</div>
          <div className="tech-section-1-equalize-explain">{techSection1Text("equalize_explain")}</div>
        </div>
        <div className="tech-section-1-equalize-sub">{techSection1Text("equalize_sub")}</div>
      </div>
      <div className="tech-section-1-grid">
        <NumberAnimationView number={4500} unit={"TPS"} numberClassName="tech-section-1-value" unitClassName="tech-section-1-unit" />
        <NumberAnimationView number={13500} unit={"TPB"} numberClassName="tech-section-1-value" unitClassName="tech-section-1-unit" />
        <p className="tech-section-1-value">
          <TransCustom nameSpace={"tech"} prefix={"section1.execution_time"}>
            _
            <span className="tech-section-1-unit" />
          </TransCustom>
        </p>
      </div>
      <LinkButton text={techSection1Text("link_whitepaper")} target={"_blank"} to={process.env.REACT_APP_WHITEPAPER_URL} className={"tech-section-1-link"} />
    </div>
  );
}

export default TechSection1;
