import React from "react";
import "./TechSection3.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import r from "../../assets/image/tech-section-3/ㄱ.svg";
import evm from "../../assets/image/tech-section-3/evm.png";
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function TechSection3() {
  const { t: techSection1Text } = useTranslationCustom({
    prefix: "section3",
    nameSpace: "tech",
  });
  useScrollAnimation(["tech-section-3"]);

  return (
    <div className={"tech-section-3 container"}>
      <img className="tech-section-3-r" src={r} alt={"ㄱ"} />
      <div className="tech-section-3-container">
        <div>
          <div className="tech-section-3-evm">{techSection1Text("evm")}</div>
          <div className="tech-section-3-evm-explain">
            {techSection1Text("evm_explain")}
          </div>
        </div>
        <img className="tech-section-3-image" src={evm} alt={"evm"} />
      </div>
    </div>
  );
}

export default TechSection3;
