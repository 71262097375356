import "./NewsList.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import qs from "qs";
import prevArrow from "../../assets/image/newsroom/prev-arrow.svg";
import nextArrow from "../../assets/image/newsroom/next-arrow.svg";
import NewsItem from "./NewsItem";
import { getNewsAction } from "../../../action/newsAction";

const LIMIT = 12;

function NewsList() {
  const { t } = useTranslationCustom({ prefix: "", nameSpace: "newsroom" });

  const categories = useMemo(() => [t("category.Press Release"), t("category.CES2023")], [t]);

  const { search } = useLocation();
  const { category: currentCategoryString, page: currentPageString } = qs.parse(search, { ignoreQueryPrefix: true });
  const currentCategory = useMemo(() => currentCategoryString ?? "", [currentCategoryString]);
  const currentPage = useMemo(() => Number(currentPageString ?? 1), [currentPageString]);

  const [news, setNews] = useState([]);
  const [count, setCount] = useState(100);

  const maxPage = useMemo(() => Math.ceil(count / LIMIT), [count]);

  const getNews = useCallback(async () => {
    const { result, error } = await getNewsAction(LIMIT, LIMIT * (currentPage - 1), currentCategory ? currentCategory : undefined, undefined);
    const { news, count } = result;
    setNews(news);
    setCount(count);
  }, [currentCategory, currentPage]);

  useEffect(() => {
    getNews();
  }, [getNews]);

  const paginations = useMemo(() => {
    if (maxPage <= 7) {
      const temp = [];
      for (let i = 2; i < maxPage; i++) {
        temp.push(i);
      }
      return temp;
    }
    if (currentPage <= 4) {
      return [2, 3, 4, 5];
    }
    if (currentPage + 3 >= maxPage) {
      const temp = [];
      for (let i = maxPage - 4; i < maxPage; i++) {
        temp.push(i);
      }
      return temp;
    }
    return [currentPage - 1, currentPage, currentPage + 1];
  }, [currentPage, maxPage]);

  const Pagination = useCallback(
    ({ page }) => (
      <Link className={`news-list-pagination-number${currentPage === page ? " active" : ""}`} to={`/newsroom?category=${currentCategory}&page=${page}`}>
        {page}
      </Link>
    ),
    [currentCategory, currentPage]
  );

  return (
    <div className={"container news-list"}>
      <div className="news-list-category-selector">
        <Link className={`news-list-category-selector-button${currentCategory ? "" : " active"}`} to={`/newsroom`} disabled={currentCategory}>
          {t("category.everything")}
        </Link>
        {categories.map((category, index) => (
          <Link
            key={`category_${index}`}
            className={`news-list-category-selector-button${category === currentCategory ? " active" : ""}`}
            to={`/newsroom?category=${category}`}
            disabled={category === currentCategory}
          >
            {category}
          </Link>
        ))}
      </div>
      <div className="news-list-container">
        {news.map((article, index) => (
          <NewsItem key={`article_${index}`} {...article} />
        ))}
      </div>
      <div className="news-list-pagination">
        <Link className="news-list-pagination-prev" to={`/newsroom?category=${currentCategory}&page=${Math.max(currentPage - 1, 1)}`}>
          <img src={prevArrow} alt="prev" />
        </Link>
        <Pagination page={1} />
        {currentPage > 4 && <span className="news-list-pagination-number">...</span>}
        {paginations.map((pagenation, index) => (
          <Pagination page={pagenation} key={`r_${index}`}>
            {pagenation}
          </Pagination>
        ))}
        {currentPage + 3 < Math.ceil(count / LIMIT) && <span className="news-list-pagination-number">...</span>}
        {maxPage !== 1 && <Pagination page={maxPage} />}
        <Link className="news-list-pagination-next" to={`/newsroom?category=${currentCategory}&page=${Math.min(currentPage + 1, maxPage)}`}>
          <img src={nextArrow} alt="next" />
        </Link>
      </div>
    </div>
  );
}

export default NewsList;
