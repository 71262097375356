import React, { useEffect, useMemo, useState } from "react";
import "./HomeSection1.scss";
import GlobeCompenent from "../../molcules/GlobeCompenent";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import LinkButton from "../../molcules/LinkButton";
import { useResponsive } from "view/styles/Responsive";
import { useSSR } from "react-i18next";

function HomeSection1() {
  const { t } = useTranslationCustom({
    prefix: "section_1",
    nameSpace: "home",
  });

  const { isMobile, isTablet, isDesktop } = useResponsive();

  const globeProps = useMemo(() => {
    if (isMobile) {
      return {
        width: 220,
        height: 220,
      };
    } else if (isTablet) {
      return {
        width: 410,
        height: 410,
      };
    } else if (isDesktop) {
      return {};
    }
  }, [isDesktop, isTablet, isMobile]);

  const [renderGlobe, setRenderGlobe] = useState(false)

  useEffect(() => {
    setTimeout(() => {setRenderGlobe(true)}, 50)
  }, [])

  return (
    <div className={"home-section-1"}>
      <div className="home-section-1-container">
        <div className="home-section-1-back-text">{t("back_text")}</div>
        <div className="home-section-1-globe">
          {renderGlobe && <GlobeCompenent {...globeProps} />}
        </div>
        <div className="home-section-1-front-text">{t("front_text")}</div>
      </div>
      <LinkButton
        className={"home-section-1-link"}
        to={"/eqbr/introduction"}
        text={t("link_vision")}
      />
    </div>
  );
}

export default HomeSection1;
