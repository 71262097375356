import React, { useMemo, useRef } from "react";
import "./TeamCultureSection2.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import left_arrow from "../../assets/image/team-culture-section-2/left_arrow.png";
import right_arrow from "../../assets/image/team-culture-section-2/right_arrow.png";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import useScrollAnimation from "../../module/animation/useScrollAnimation";
import JSH from "./../../assets/image/team-culture-section-2/JSH.png";
import JIP from "./../../assets/image/team-culture-section-2/JIP.png";
import WYK from "./../../assets/image/team-culture-section-2/WYK.png";
import SHP from "./../../assets/image/team-culture-section-2/SHP.JPG";
import DCY from "./../../assets/image/team-culture-section-2/DCY.JPG";

const personImg = [JSH, JIP, WYK, SHP, DCY];

SwiperCore.use([Pagination, Navigation]);

function TeamCultureSection2() {
  const { t, i18n } = useTranslationCustom({
    prefix: "section_2",
    nameSpace: "team_culture",
  });

  const swiperRef = useRef(null);

  const personWordList = useMemo(() => {
    const personWordTextList = t("personWord", { returnObjects: true });
    return personWordTextList.map((item, index) => {
      return { ...item, image: personImg[index] };
    });
  }, [t]);

  useScrollAnimation(["team-culture-section-2"]);
  return (
    <div className={"team-culture-section-2"}>
      <div className="content-wrapper container">
        <div
          className={"swiper-arrow-wrapper swiper-arrow-wrapper-left"}
          onClick={() => swiperRef.current.swiper.slidePrev()}
        >
          <div className={"swiper-arrow"}>
            <img className={"icon"} src={left_arrow} alt="left" />
          </div>
        </div>
        <Swiper
          ref={swiperRef}
          pagination={{
            dynamicBullets: false,
            dynamicMainBullets: 1,
          }}
          speed={500}
          allowTouchMove={true}
          modules={[Navigation, Pagination]}
          className={"introduction-swiper"}
        >
          {personWordList.map((el, index) => (
            <SwiperSlide key={`${el.text}_${index}`}>
              <div className="swiper-content">
                <img className="introduction-img" src={el.image} alt="" />
                <div className="introduction-text">{el.a_word}</div>
                <div className="introduction-writer">{el.who}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={"swiper-arrow-wrapper swiper-arrow-wrapper-right"}
          onClick={() => swiperRef.current.swiper.slideNext()}
        >
          <div className={"swiper-arrow"}>
            <img className={"icon"} src={right_arrow} alt="right" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamCultureSection2;
