import React from "react";

function NotFound(props) {
  return (
    <div>
      <div className="container"></div>
    </div>
  );
}

export default NotFound;
