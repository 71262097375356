import TechSection1 from "../components/tech/TechSection1";
import PageHeader from "../molcules/PageHeader";
import TechSection2 from "../components/tech/TechSection2";
import TechSection3 from "../components/tech/TechSection3";
import TechSection4 from "../components/tech/TechSection4";
import './Tech.scss';
import { useEffect, useState } from "react";

export default function Tech() {

  const [renderAfter, setRenderAfter] = useState(false)

  useEffect(() => {
    setTimeout(() => { setRenderAfter(true) }, 100)
  }, [])

  return (
    <div id={'tech'}>
      <PageHeader prefix={"tech"} />
      <TechSection1 />
      {renderAfter && <>
        <TechSection2 />
        <TechSection3 />
        <TechSection4 />
      </>}
    </div>
  );
}