import React, { useCallback, useEffect, useState } from "react";
import "./HomeSection3.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import { getNewsAction } from "action/newsAction";
import { useResponsive } from "view/styles/Responsive";
import HomeNewsItem from "./HomeNewsItem";
import LinkButton from "view/molcules/LinkButton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

const LIMIT = 3;

function HomeSection3() {
  const { t } = useTranslationCustom({
    prefix: "section_3",
    nameSpace: "home",
  });

  const { isMobile, isTablet, isDesktop } = useResponsive();

  const [news, setNews] = useState([]);

  const getNews = useCallback(async () => {
    const { result, error } = await getNewsAction(LIMIT);
    const { news, count } = result;
    setNews(news);
  }, []);

  const [renderAfter, setRenderAfter] = useState(false)

  useEffect(() => {
    setTimeout(() => { 
      setRenderAfter(true)
      getNews();
    }, 300)
  }, [getNews])

  return (
    <div className={"home-section-3"}>
      {renderAfter && <div className={"home-section-3-container container"}>
        <div className="home-section-3-title">{t("title")}</div>
        {isMobile && (
          <Swiper
            pagination={{
              dynamicBullets: false,
              dynamicMainBullets: 1,
            }}
            speed={500}
            allowTouchMove={true}
            modules={[Navigation, Pagination]}
            spaceBetween={30}
            className={"home-section-3-swiper container"}
          >
            {news.map((item, index) => (
              <SwiperSlide key={`k_${index}`}>
                <HomeNewsItem {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {isTablet && (
          <div className="home-section-3-news-grid">
            {news.slice(0, 2).map((item, index) => (
              <HomeNewsItem {...item} key={`k_${index}`}/>
            ))}
          </div>
        )}
        {isDesktop && (
          <div className="home-section-3-news-grid">
            {news.map((item, index) => (
              <HomeNewsItem {...item} key={`k_${index}`}/>
            ))}
          </div>
        )}

        <LinkButton
          to={"/newsroom"}
          text={t("link_newsroom")}
          className={"home-section-3-link"}
        />
      </div>}
    </div>
  );
}

export default HomeSection3;
