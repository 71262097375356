import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./launges/en.json";
import ko from "./launges/ko.json";

const resources = {
  ko,
  en,
};
// Get supported languages
export function getLanguages() {
  return {
    ko: "한국어",
    en: "English",
  };
}

let locale = navigator.language || navigator.userLanguage;

const sessionLan = sessionStorage.getItem("language");

if (sessionLan) {
  locale = sessionLan;
} else {
  switch (locale) {
    case "ko":
    case "ko-KR":
      locale = "ko";
      break;
    case "en":
    case "en-US":
    default:
      locale = "en";
  }
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: locale,
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
