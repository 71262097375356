import {getNewsByIdFromServer, getNewsFromServer, GetNewsResponse} from '../server/newsAPI';
import {ActionType, parseAction} from './util/parseAction';
import {News} from "../locales/reducer/newsType";
import _ from 'lodash';

export const getNewsAction = (limit: number, offset?: number, category?: string, hashtag?: string)
  : Promise<ActionType<{count: number, news: News[]}>> =>
  parseAction(async () => {
    const news: GetNewsResponse = await getNewsFromServer({
      query: {
        category,
        hashtag,
        limit,
        offset
      }
    });
    return {
      count: news.count,
      news : news.rows
    };
  });

export const getNewsByIdAction = (newsId: number)
  : Promise<ActionType<News>> =>
  parseAction(async () => {
    const news = await getNewsByIdFromServer({
      params: {newsId}
    });
    return news
  });

export const getRelativeNewsAction = (newsId: number, limit: number, hashtag: string)
  : Promise<ActionType<News[]>> =>
  parseAction(async () => {
    if(!hashtag) {
      throw new Error('no hashtag');
    }
    const hashTagList = hashtag.split(',').map(el => el.trim());

    const allList = await Promise.all(
      hashTagList.map(async el => {
        const news: GetNewsResponse = await getNewsFromServer({
          query: {
            hashtag: el,
            limit  : limit + 1,
          }
        });
        return news.rows.filter(ell => ell.news_id !== newsId);
      })
    );

    return _.sortBy(_.uniqBy(allList.flat(), 'news_id'), 'news_id').reverse().slice(0, limit);
  });
