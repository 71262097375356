import React, { useMemo } from "react";
import "./TechSection4.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import NewsItem from "../newsroom/NewsItem";
import pressImage1 from "../../assets/image/tech-section-4/1.webp";
import pressImage2 from "../../assets/image/tech-section-4/2.webp";
import pressImage3 from "../../assets/image/tech-section-4/3.webp";
import useScrollAnimation from "../../module/animation/useScrollAnimation";

function TechSection4() {
  const { t: techSection1Text } = useTranslationCustom({
    prefix: "section4",
    nameSpace: "tech",
  });
  useScrollAnimation(["tech-section-4"]);

  const presses = useMemo(
    () => [
      {
        title: techSection1Text("press1_title"),
        category: techSection1Text("press"),
        hashtag: techSection1Text("press1_hashtag"),
        img_url: pressImage1,
        link: "https://medium.com/eqbr/equilibrium-consensus-1-3b0abc95c7d4",
      },
      {
        title: techSection1Text("press2_title"),
        category: techSection1Text("press"),
        hashtag: techSection1Text("press2_hashtag"),
        img_url: pressImage2,
        link: "https://medium.com/eqbr/equilibrium-consensus-2-a64ca9fd1d20",
      },
      {
        title: techSection1Text("press3_title"),
        category: techSection1Text("press"),
        hashtag: techSection1Text("press3_hashtag"),
        img_url: pressImage3,
        link: "https://medium.com/eqbr/equilibrium-micro-chain-structure-1-b6eba253f6c2",
      },
    ],
    [techSection1Text]
  );

  return (
    <div className={"tech-section-4 container"}>
      <div className="tech-section-4-title">
        {techSection1Text("tech_blog")}
      </div>
      <div className="tech-section-4-hashtag">
        {techSection1Text("tech_blog_hashtag")}
      </div>
      <div className="tech-section-4-grid">
        {presses.map((press, index) => (
          <NewsItem key={`p_${index}`} {...press} />
        ))}
      </div>
    </div>
  );
}

export default TechSection4;
