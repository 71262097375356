import React, { useEffect, useState } from "react";
import "./Brand.scss";
import PageHeader from "view/molcules/PageHeader";
import BrandSection1 from "../components/brand/BrandSection1";
import BrandSection2 from "../components/brand/BrandSection2";
import BrandSection3 from "../components/brand/BrandSection3";
import BrandSection4 from "../components/brand/BrandSection4";

const Brand = (props) => {
  const [renderAfter, setRenderAfter] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRenderAfter(true);
    }, 100);
  }, []);

  return (
    <div id="Brand">
      <PageHeader prefix={"brand"} />
      <BrandSection1 />
      {renderAfter && (
        <>
          <BrandSection2 />
          <BrandSection3 />
          <BrandSection4 />
        </>
      )}
    </div>
  );
};

export default Brand;
