import React from "react";
import "./BrandSection4.scss";
import useScrollAnimation from "view/module/animation/useScrollAnimation";
import Recruit from "../Recruit";

const BrandSection4 = () => {
  useScrollAnimation(["brand-section-4"]);

  return (
    <div className="brand-section-4">
      <Recruit />
    </div>
  );
};

export default BrandSection4;
