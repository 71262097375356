import './NewsItem.scss';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';

function NewsItem(article) {

    const to = useMemo(() => article.news_id ? `/newsroom/${article.news_id}` : article.link, [article])

    return <Link className='news-item'
        to={to}
    >
        <img className='news-item-image' src={article.img_url} alt={article.title} />
        <div className='news-item-content'>
            <div className='news-item-category'>{article.category}</div>
            <div className='news-item-title'>{article.title}</div>
            <div className='news-item-hashtag-container'>
                {article.hashtag.split(',').map((item, index) => <span key={`hashtag_${index}`} className='news-item-hashtag'>#{item.trim()}</span>)}
            </div>
        </div>
    </Link>
}

export default NewsItem;
