import React, { useCallback, useState } from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import logo from "../assets/image/logo/logo.png";
import useTranslationCustom from "../assets/image/community-section-1/useTranslationCustom";
import useRouter from "../router/useRouter";
import twitterIcon from "../assets/image/footer-twitter.png";
import mediumIcon from "../assets/image/footer-medium.png";
import youtubeIcon from "../assets/image/footer-youtube.png";
import linkedinIcon from "../assets/image/footer-linkedin.png";

const links = [
  {
    icon: twitterIcon,
    link: "https://twitter.com/OfficialEqbr",
  },
  {
    icon: mediumIcon,
    link: "https://medium.com/eqbr",
  },
  {
    icon: youtubeIcon,
    link: "https://www.youtube.com/channel/UCwjHVefVkToqi9FfGTlFakw",
  },
  {
    icon: linkedinIcon,
    link: "https://kr.linkedin.com/company/eqbr",
  },
];

function Footer() {
  const { t } = useTranslationCustom({ prefix: "", nameSpace: "footer" });

  const router = useRouter();

  const [selectedRouter, setSelectedRouter] = useState();

  const onClickRouter = useCallback((route) => {
    if (route.sub) {
      setSelectedRouter(route);
    }
  }, []);

  return (
    <footer className={"container footer"}>
      <img className={"footer-logo"} src={logo} alt={"logo"} />
      <div className={"footer-container"}>
        {router.map((route, index) => (
          <div className={"footer-content"} key={`f_${index}`}>
            <div className={"main"} onClick={() => onClickRouter(route)}>
              {route.label}
            </div>
            {route?.sub?.map((item, j) => (
              <Link
                to={item.link}
                key={`l_${j}`}
                className="footer-content-link"
              >
                {item.label}
              </Link>
            ))}
          </div>
        ))}
      </div>
      <div className="footer-links">
        {links.map((item) => (
          <button
            onClick={() => window.open(item.link)}
            key={`link_${item.link}`}
          >
            <img
              className={"footer-links-icon"}
              src={item.icon}
              alt={item.link}
            />
          </button>
        ))}
      </div>
      <div className="footer-bottom-wrapper">
        <div className="footer-bottom">{t("copyright")}</div>
        <div className="footer-bottom address">{t("company_address")}</div>
        <div className="footer-bottom">{t("company_num")}</div>
        <div className="footer-bottom">{t("ceo_name")}</div>
        <div className="footer-bottom">{t("tel_num")}</div>

      </div>
    </footer>
  );
}

export default Footer;
