import "./Home.scss";
import HomeSection1 from "../components/home/HomeSection1";
import HomeSection2 from "../components/home/HomeSection2";
import HomeSection3 from "../components/home/HomeSection3";
import HomeSection4 from "../components/home/HomeSection4";
import ReactFullpage from "@fullpage/react-fullpage";
import SectionDownButton from "view/components/home/SectionDownButton";
import Footer from "view/components/Footer";
import { Desktop } from "view/styles/Responsive";

function Home() {
  return (
    <ReactFullpage
      licenseKey={process.env.REACT_APP_FULLPAGE_LICENSE_KEY}
      scrollingSpeed={500} /* Options here */
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <HomeSection1 />
              <Desktop>
                <SectionDownButton fullpageApi={fullpageApi} />
              </Desktop>
            </div>
            <div className="section">
              <HomeSection2 />
              <Desktop>
                <SectionDownButton fullpageApi={fullpageApi} />
              </Desktop>
            </div>
            <div className="section">
              <HomeSection3 />
              <Desktop>
                <SectionDownButton fullpageApi={fullpageApi} />
              </Desktop>
            </div>
            <div className="section">
              <HomeSection4 />
              <Footer />
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
}

export default Home;
