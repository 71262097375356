import {useEffect} from "react";

const scrollListenerForAnimation = (className, pxFromBottom) => {
    const elements = document.getElementsByClassName(className);
    const viewportHeight = window.innerHeight;
    for (let i = 0; i < elements.length; ++i) {
        const element = elements[i];
        const distanceFromViewportTop = element.getBoundingClientRect().top;
        const distanceFromViewportBottom = element.getBoundingClientRect().bottom;
        const isVisible =
            viewportHeight >= distanceFromViewportTop + pxFromBottom &&
            distanceFromViewportBottom > 0;
        const animations = element.getAnimations();
        if(isVisible){
            animations.forEach(el => {
                el.play();
            })
        }
    }
}

const startAnimationByScroll = (className, pxFromBottom) => {
    const listener = ()=>scrollListenerForAnimation(className, pxFromBottom);
    window.addEventListener('scroll', listener);
    return listener;
}

export default function useScrollAnimation (classNames, pxFromBottom = 300) {
    useEffect(()=>{
        const listeners = classNames.map(className => {
            const listener = startAnimationByScroll(className, pxFromBottom)
            return listener;
        })
        return ()=> {
            listeners.forEach(el => {
                window.removeEventListener('scroll', el)
            })
        };
    },[])
}
