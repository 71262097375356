import {getRecruitsFromServer, getRecruitByIdFromServer, GetRecruitsResponse} from '../server/employmentAPI';
import {ActionType, parseAction} from './util/parseAction';
import {Employment} from '../locales/reducer/employmentType';

export const getEmploymentsAction = (selectedLanguage: string, limit: number, offset?: number, lastId?: number)
    : Promise<ActionType<{ count: number, employments: Employment[] }>> =>
    parseAction(async () => {
        
        const employments: GetRecruitsResponse = await getRecruitsFromServer(
            {
                language: selectedLanguage,
            query: {
                limit,
                offset,
                lastId
            }
        });
        return {
            count: employments.count,
            employments: employments.employments
        };
    });

export const getEmploymentByIdAction = (selectedLanguage: string, employmentId: number)
    : Promise<ActionType<Employment>> =>
    parseAction(async () => {
        
        const employment = await getRecruitByIdFromServer({
            language: selectedLanguage,
            params: {employmentId}
        });
        return employment
    });

