import {newsServer} from './utils/serverInstances';
import {makeQuery} from './utils/makeQuery';

const getNews = async(
    request: GetNewsRequest
): Promise<GetNewsResponse> =>
await newsServer.get({
    path: 'api/v1/news',
    query: makeQuery(request.query)
})
type GetNewsRequest = {
    query: GetNewsQuery;
}
type GetNewsQuery = {
    category?: string;
    hashtag?: string;
    limit: number;
    offset?: number;
}
export type GetNewsResponse = {
    count: number;
    rows: Row[]
}
type Row = {
    news_id: number;
    category: string;
    date: Date;
    hashtag: string;
    img_url: string;
    text: string;
    title: string;
}

const getNewsById = async(
    request: getNewsByIdRequest
): Promise<Row> =>
await newsServer.get({
    path: `api/v1/news/${request.params.newsId}`,
})
type getNewsByIdRequest = {
    params: {
        newsId: number
    };
}
export {
    getNews as getNewsFromServer,
    getNewsById as getNewsByIdFromServer
}
